<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 地址管理 </template>
      <template #input>
        <a-button class="all_boder_btn" @click="newbuilt()">新建地址</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        :locale="{ emptyText: '暂无数据' }"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <template slot="operation" slot-scope="item, row">
          <div class="btn_router_link">
            <a @click="edit(row)">编辑</a>
            <span>|</span>
            <a @click="deleteitem(row)">删除</a>
          </div>
        </template>
      </a-table>
    </div>

    <!-- 新建/编辑 -->
    <a-modal
      v-model="visible"
      width="700px"
      :title="(state == 1 ? '新建' : '编辑') + '收货地址'"
      okText="确认"
      :confirm-loading="PreventLoad"
      @ok="pushData"
    >
      <a-row class="rows">
        <a-col span="4" class="left">收货人</a-col>
        <a-col span="20" class="right">
          <a-input v-model="formOBJ.name" placeholder="请输入收货人" />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="4" class="left">手机号码</a-col>
        <a-col span="20" class="right">
          <a-input v-model="formOBJ.mobile" placeholder="请输入手机号码" />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="4" class="left">收货地址</a-col>
        <a-col span="20" class="right">
          <Province
            style="width: 100%"
            @regionCity="FunProvince"
            :value="shippingAddressValue"
          ></Province>
        </a-col>
      </a-row>
      <a-row class="rows" style="margin-bottom: 0">
        <a-col span="4" class="left">详细地址</a-col>
        <a-col span="20" class="right">
          <a-input v-model="formOBJ.address" placeholder="请输入详细地址" />
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "收货人",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号码",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "收货地址",
    align: "center",
    dataIndex: "pcaName",
  },
  {
    title: "操作",
    align: "center",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
import Province from "@/components/provinceCity";

export default {
  // 可用组件的哈希表
  components: { HeaderBox, Province },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      state: 1, // 1新建，2编辑
      visible: false,
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      // 发货省市区
      shippingAddressValue: [],
      // 新建时数据
      formOBJ: {
        province: null,
        city: null,
        area: null,
        address: "",
        mobile: "",
        name: "",
      },
      PreventLoad: false,
    };
  },
  // 事件处理器
  methods: {
    // 获取发货省市区编码
    FunProvince(data) {
      this.formOBJ.province = data[0];
      this.formOBJ.city = data[1];
      this.formOBJ.area = data.length > 2 ? data[2] : "";

      this.shippingAddressValue = data;
    },
    // 分页
    onPage(e) {
      // console.log(e);
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getdataList();
    },
    // 新建
    newbuilt() {
      for (let key in this.formOBJ) {
        this.formOBJ[key] = null;
      }
      this.shippingAddressValue = [];
      this.state = 1;
      this.visible = true;
    },
    // 编辑
    edit(e) {
      this.state = 2;
      this.visible = true;
      for (let key in e) {
        this.formOBJ[key] = e[key];
      }
      this.shippingAddressValue = [e.province, e.city]; // 省市区回显
      if (e.area) {
        // 判断部分地区没有区
        this.shippingAddressValue.push(e.area);
      }
      // console.log(this.shippingAddressValue);
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      // this.pageSize = 10;
      this.getdataList();
    },
    // 获取数据
    getdataList() {
      this.$ajax({
        url: "/hxclass-management/admin-address/list",
        method: "get",
        params: {
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
          // keyWord: this.name,
        },
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },

    // 删除对象
    deleteitem(e) {
      let _this = this;
      this.$confirm({
        title: "确定删除该标签吗?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url:
                "/hxclass-management/admin-address/delete?addressId=" +
                e.addressId,
              method: "DELETE",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.visible = false;
                _this.getdataList();
              }
            });
        },
        onCancel() {},
      });
    },
    // 新增/编辑
    pushData() {
      if (!this.formOBJ.name) {
        this.$message.warning("请填写收货人");
        return;
      }
      if (!this.formOBJ.mobile) {
        this.$message.warning("请填写手机号码");
        return;
      }
      if (!this.$regular.phone.reg.test(this.formOBJ.mobile)) {
        this.$message.warning(this.$regular.phone.msg);
        return;
      }
      if (!this.shippingAddressValue.length) {
        this.$message.warning("请选择收货地址");
        return;
      }
      if (!this.formOBJ.address) {
        this.$message.warning("请填写详细地址");
        return;
      }

      let urls = "";
      let method = "";
      // 只有!1的时候是新建
      if (this.state != 1) {
        urls = "/hxclass-management/admin-address/update";
        method = "POST";
      } else {
        urls = "/hxclass-management/admin-address/insert";
        method = "POST";
      }
      this.PreventLoad = true;
      this.$ajax({
        url: urls,
        method: method,
        params: this.formOBJ,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          for (let key in this.formOBJ) {
            this.formOBJ[key] = null;
          }
          this.getdataList();
          this.shippingAddressValue = [];
          this.visible = false;
        } else {
          this.$message.error("操作失败");
          this.visible = false;
        }
        this.PreventLoad = false;
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getdataList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
/deep/.ant-cascader-picker {
  width: 100%;
}

.table {
  margin-top: 24px;
  /deep/.ant-table-thead > tr > th {
    background: #f6f6fc;
  }
  /deep/.ant-table-footer {
    background: #f6f6fc;
    padding: 12px 16px;
  }
  /deep/.ant-table-thead > tr > th,
  /deep/.ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
  /deep/.ant-table {
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  }
  th.column-money,
  td.column-money {
    text-align: right !important;
  }
  .operation {
    color: #3681f0;
  }
  .allSellect {
    padding-left: 6px;
  }
}
.rows {
  margin-bottom: 20px;
  .left {
    text-align: right;
    height: 32px;
    line-height: 32px;
    &::before {
      content: "*";
      color: red;
      margin-right: 2px;
    }
    &::after {
      content: "：";
    }
  }
}
</style>
